<template>

    <div>
        <van-nav-bar class="nav-bar" title="业主退租" left-arrow @clickLeft="onClickLeft" ></van-nav-bar>
        <div class="applyTime">申请时间：&nbsp;{{approvalTime}}</div>
        <div class="container-normal container-normal-header">
            <div class="wait-approve">待审批</div>
            <div class="houseAddress">星海广场-中山路535-1-3号1单元-501星海广场-中山路535-1-3号1星海广场-中山路535-1-3号1</div>
        </div>
        <div class="dividing-line"></div>
        <div class="container-normal">
            <table  class="rentInfo">
                <tr>
                    <td style="width: 152px"><div class="font-bold">合同编号：<span class="font-normal ">{{agreement.id}}</span></div></td>
                    <td ><div class="font-bold ">合同状态：<span class="font-normal  orangeColor">{{agreement.state}}</span></div></td>
                </tr>
                <tr>
                    <td style="width: 152px"><div class="font-bold">租客姓名：<span class="font-normal">{{agreement.tenantName}}</span></div></td>
                    <td><div class="font-bold">付款方式：<span class="font-normal">{{agreement.payType}}</span></div></td>
                </tr>
                <tr>
                    <td style="width: 152px"><div class="font-bold">租金：<span class="font-normal">{{agreement.rent}}元</span></div></td>
                    <td><div class="font-bold">押金：<span class="font-normal">{{agreement.deposit}}元</span></div></td>
                </tr>
            </table>
        </div>
        <div class="dividing-line"></div>
        <div class="container-normal">
            <table  class="rentInfo">
                <tr>
                    <td style="width: 152px" >
                        <div class="font-bold">
                            正常退租：<span class="font-normal  orangeColor">{{checkout_Info.checkout_type}}</span>
                        </div>
                    </td>
                    <td>
                        <div class="font-bold ">
                            解除日期：<span class="font-normal orangeColor ">{{checkout_Info.termination_date}}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="width: 152px">
                        <div class="font-bold">
                            违约方：<span class="font-normal ">{{checkout_Info.breach_part}}</span>
                        </div>
                    </td>
                    <td>
                        <div class="font-bold">
                        有责：<span class="font-normal orangeColor">{{checkout_Info.responsible}}</span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="dividing-line"></div>
        <div class="container-normal">
            <div class="content-box-top">
                <span class="font-bold ">解除类型：</span>
                <span class="font-normal addPaddding-Left">{{checkout_type}}</span>
            </div>
            <div class="content-box-bottom">
                <span class="font-bold ">解除原因：</span>
                <span class="font-normal  addPaddding-Left">{{checkout_reason}}</span>
            </div>
        </div>
        <div class="dividing-line"></div>
        <div class="container-normal container-normal-foot">
            <div @click="contractDetail" class="view-Button">查看合同</div>
        </div>

        <div class="leftTitle"><span >退款详情</span></div>
        <div class="container-normal container-normal-header">
            <table  class="detailInfo">
                <tr class="tr-top">
                    <td><div class="detailLine-title-left">剩余租金：</div></td>
                    <td><div class="detailLine-content-right">￥344.00</div></td>
                </tr>
                <tr>
                    <td><div class="detailLine-title-left">扣除租金：</div></td>
                    <td><div class="detailLine-content-right">-￥344.00</div></td>
                </tr>
                <tr>
                    <td><div class="detailLine-title-left">违约金：</div></td>
                    <td><div class="detailLine-content-right">￥344.00</div></td>
                </tr>
                <tr class="tr-bottom">
                    <td><div class="detailLine-title-left">装修赔偿：</div></td>
                    <td><div class="detailLine-content-right">￥344.00</div></td>
                </tr>
            </table>
        </div>
        <div class="dividing-line"></div>
        <div class="container-normal ">
            <table  class="detailInfo">
                <tr class="tr-top">
                    <td><div class="detailLine-title-left redColor">公司退款总额：</div></td>
                    <td><div class="detailLine-content-right redColor font-bold">￥2342.00</div></td>
                </tr>
                <tr>
                    <td><div class="detailLine-title-left redColor">业主退回总额：</div></td>
                    <td><div class="detailLine-content-right redColor font-bold">￥2342.00</div></td>
                </tr>
            </table>
        </div>
        <div class="dividing-line"></div>
        <div class="container-normal container-normal-foot">
            <div class="view-Button view-Button-wider">查看解除协议</div>
        </div>


        <div class="normalBlock" >
            <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
            <span class="blockTitle">审核结果</span>
            <div class="rightText">
                <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
                <span class="content-divide" > | </span>
                <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
            </div>
        </div>
        <div class="normalBlock" v-show="isPass" >
            <span class="maker" :class="{'orangeColor':(guidingPrice!='')}">• </span>
            <span class="blockTitle">指导价</span>
            <span class="content-divide">|</span>
            <input  type="number" class="inputPrice" v-model="guidingPrice" >
            <span class="priceUnit">元</span>
        </div>

        <div id="addressPanel" class="part-inputpart-row" v-show="isReject" @click="isReasonShow = !isReasonShow">
            <span class="maker" :class="{'orangeColor':isReasonSelect}" >• </span>
            <span class="part-inputpart-row-header">原因</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="isReasonSelect ? 'part-inputpart-row-normaltext':'' ">{{rejectReason}}</span>
            <img class="part-inputpart-row-right" :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
        </div>
<!--        <div>-->
<!--            <div class="part-inputpart part-inputpart-dropDownList" v-if="isReasonShow">-->
<!--                <div class="part-inputpart-dropDownList-option" :class="item==rejectReason ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in reasonArr" @click="selectAddressOption($event)">{{item}}</div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="container-sometext" v-show="isReject">
            驳回次数：{{rejectCount}}  <span style="padding-left: 3px;padding-right: 2px">|</span> 上次驳回原因：{{lastRejectReason}}
        </div>
        <div class="container-normal container-normal-radius"  >
            <div><span class="suggestTitle">审批意见</span></div>
            <div><textarea  class="textArea" ></textarea></div>
        </div>
        <van-button  class="saveButton_Disable" :class="{'saveButton_Enable': buttonEnable}" :disabled=" !buttonEnable" @click="save" >保存</van-button>
      <van-popup v-model="isReasonShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="reasonArr"
            @cancel="isReasonShow = false"
            :default-index="reasonIndex"
            @confirm="selectAddressOption"
        />
      </van-popup>
    </div>

</template>

<script>


import {
  NavBar,
  ImagePreview, Button, Image as VanImage, Picker, Popup
} from 'vant';
    import BScroll from "better-scroll";

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Button.name]: Button,
            [VanImage.name]:VanImage,
            [ImagePreview.Component.name]: ImagePreview.Component,
            [BScroll.name]:BScroll,
          [Picker.name]: Picker,
          [Popup.name]: Popup,
        },
        data(){
           return {
             reasonIndex: '',
               rejectCount:2,
               lastRejectReason:'这里是原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因',
               checkout_type:'不知道类型',
               checkout_reason:'这里是原因原因原因原因原因原因原因原因原因原因原因原因原因原因原因',
               approvalStatusStr:"",
               approvalType:"",
               isReasonSelect:false,
               rejectReason:'请选择',
               reasonArr: [
                   '原因1',
                   '原因2',
                   '原因3',
                   '原因4'
               ],
               guidingPrice:"",
               isPass:false,
               isReject:false,
               isSelect:'',
               isReasonShow:false,
               approvalTime:'2019-08-22',
               builtUpArea:'132',

               agreement:
               {
                   id:'2020-03-02',
                   state:'执行中',
                   tenantName:'陈赫赫',
                   payType:'押一付三',
                   rent:2313,
                   deposit:231
               },
               checkout_Info:{
                   checkout_type:'否',
                   termination_date:'2020-04-24',
                   breach_part:'房主',
                   responsible:'是'
               },
               imgList:["https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg",
                   "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg",
                   "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg",
                   "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1936229199,1233983350&fm=26&gp=0.jpg",
               ]
           }
        },
        methods: {

            contractDetail() {
              this.$router.push({
                  name: 'contractDetail',
                  query: {

                  }
              })
            },

            onClickLeft(){},
            previewImg(start){
                ImagePreview(
                    {
                        images: this.imgList,
                        startPosition: start,
                        onClose() {
                            // do something
                        },
                    }
                )
            },
            choosePass(){
                this.isPass = true;
                this.isReject = false;
            },
            chooseReject(){
                this.isReject = true;
                this.isPass = false;
            },
            selectAddressOption(item,index){
                this.rejectReason = item
              this.reasonIndex = index
                this.isReasonShow = false
                this.isReasonSelect = true
            },
            clickAddressShow() {
                this.isReasonShow = !this.isReasonShow;
                var panel = document.getElementById('addressPanel')
                document.addEventListener('click',e =>{
                    if(!panel.contains(e.target)){
                        this.isReasonShow = false
                    }
                })
            },
            save(){
                //todo
            }
        },
        computed:
            {
                buttonEnable () {
                    return (this.isPass && (this.guidingPrice>0) ) || (this.isReject && this.isReasonSelect)
                }
            }
    }
</script>

<style lang="less">
    @paddingLeft:10px;

    .dividing-line{
        height: 1px;
        margin: 0 0.5rem;
        background-color: #e8e3e3;
    }
    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        border-radius: 8px;
    }
    .part-inputpart-dropDownList{
        width: 90%;
        position: absolute;
        left: 19px;
        z-index: 99;
        margin-top: -17px;

    }
    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }
    .part-inputpart-dropDownList-option-selected{
        color: #ff5d3b;
    }
    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }
    .part-inputpart-row-header{
        font-weight: bold;
        font-size: 14px;
        width: 13%;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
        font-size: 14px;
    }

    .part-inputpart-row-normaltext{
        color: black;
    }
    .part-inputpart-row-right{
        float: right;
        margin-right: 10px;
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }
    .part-inputpart-row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: white;
        border-radius: 9px;
        width:9rem;
        margin: 19px auto;
    }
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin:0 10px;
    }
    .content-divide-small {
        font-size: 14px;
       // color: #9a9a9a;
        margin:0 5px;
    }
    .selectReason{
        width: 245px;
        height: 1rem;
        line-height: 28px;
        margin-left: 20px;
        font-size: 14px;
        border-style: none;
    }
    .suggestTitle{
        margin-left: 14px;
        margin-top:12px;
        float: left;
        font-size: 15px;
        font-weight: bold;
        display: block;
    }
    .textArea{
        resize: none;
        padding: 10px;
        margin:38px auto 15px;
        height: 120px;
        width: 290px;
        display: block;
        font-size: 14px;
        border-radius: 8px;
        border-style: none;
        background-color: rgba(182, 177, 177, 0.11);
    }

    .priceUnit{
        color: rgba(153, 153, 153, 0.45);
        font-size: 14px;
        float: right;
        padding-right: 31px;
    }
    .inputPrice{
        color:#ff3c00;
        width: 100%;
        height: 28px;
        line-height: 28px;
        font-size: 15px;
        //font-weight: bold;
        border: none;
    }
    .divide{
        font-size: 14px;
        color: #efefef;
        margin: 0 10px;
    }
    .passText{
        flex: 1;
        padding-right: 7px;
    }
    .rejectText{
       padding-left: 7px;
       padding-right: 33px;

    }
    .rightText{
        margin-left:auto;
        font-size: 14px;
        color: #999999;
    }

    .maker{
        color: #999999;
        float: left;
        padding-left: 14px;
        padding-right: 14px;
        font-size: 28px;
    }
    .wait-approve{
        width: 46px;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .orangeColor{
        color: #ff3c00;
    }
    .redColor{
        color: red;
    }
    .choosedStyle{
        color: #ff3c00;
        font-weight: bold;
    }

    .blockTitle{
        overflow: hidden;
        float: left;
        font-size: 15px;
        font-weight: bold;
        width: 27%;
    }

    .detailLine-title-left{
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        padding-left: @paddingLeft;
    }
    .detailLine-content-right{
        font-size: 14px;
        font-weight: normal;
        text-align: right;
        padding-right: 13px;
    }

    .font-bold{
        font-size: 14px;
        font-weight: bold;
    }
    .font-normal{
        font-size: 14px;
        font-weight: normal;
    }

    .content-box-top{
        height: auto;
        font-size: 14px;
        padding: 5px 15px 0 @paddingLeft;
        margin-top: 13px;
    }
    .content-box-bottom{
        height: auto;
        line-height: 23px;
        font-size: 14px;
        padding: 3px 15px 0px @paddingLeft;
        margin-bottom: 18px;
    }
    .addPaddding-Left{
        padding-left: 3px;
    }
    .text-toRight{
        text-align: right;
    }
    .rentInfo{
        padding-top: 14px;
        padding-bottom: 14px;
        width:9.2rem;
        padding-left: @paddingLeft;
    }
    .detailInfo{
        margin: 13px auto;
        width:9rem;
    }
    .applyTime{
        font-size: 12px;
        text-align: right;
        //color: #999999;
        padding-right: 19px;
        margin-top: 16px;
        margin-bottom: 6px;
    }
    .houseAddress{
        font-size: 16px;
        font-weight: bold;
        height: auto;
        padding: 8px @paddingLeft;
    }
    .leftTitle{
        font-size: 13px;
        color: #ea5600;
        font-weight: bold;
        margin-left: 0.5rem;
        margin-bottom: 4px;
        margin-top: 33px;
    }
    .nav-bar{
        background-color: #f8f8f8;
        border: none;
        font-weight: bold;
    }

    .saveButton_Disable{
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 9rem;
        height: 1.22rem;
        border-radius: 8px;
        margin:30px 19px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

    }
    .middle{
        overflow:hidden;
        height: auto;
    }
    .container-normal {
        overflow: hidden;
        background-color: white;
        width:9rem;
        height: auto;
        margin: 0px auto;
    }
    .container-normal-radius{
        border-radius: 10px;
    }
    .container-normal-header{
        border-top-right-radius:10px;
        border-top-left-radius:10px;
    }
    .container-normal-foot{
        border-bottom-right-radius:10px;
        border-bottom-left-radius:10px;
    }
    .container-sometext{
        width:8.5rem;
        height: auto;
        line-height: 18px;
        margin: -13px auto 20px 29px;
        font-size: 13px;
        color: #726a6a;
    }
    .view-Button{
        color: red;
        height: 30px;
        width: 80px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        //font-weight: bold;
        padding-top: 2px;
        margin: 13px 12px 13px auto;
        border-radius: 11px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(135deg, #ffffff, #fff), linear-gradient(135deg, #efa359, #e04c4c);
        border: 1px transparent solid;
    }
    .view-Button-wider{
        width:105px
    }
    .normalBlock{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        border-radius: 9px;
        width:9rem;
        line-height: 1rem;
        height: 50px;
        margin: 19px auto;
    }

    .imgCount{
        background-color: black;
        opacity: 0.5;
        position: absolute;
        font-size: 9px;
        left: 12px;
        top:14px;
        z-index: 1;
        color: white;
        width: 30px;
        text-align: center;
        border-radius:10px ;
    }
    .houseImage{
        width: 97px;
        height: 73px;
        margin-top: 11px;
        margin-left: 8px;
        margin-bottom: 11px;
        float: left;
        display: block;
    }
</style>
